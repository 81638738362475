import useSWR from "swr"

import LoadingSkeleton from "components/LoadingSkeleton"
import ValidatingWrapper from "components/ValidatingWrapper"

import TenantRow from "./TenantRow"

import type { GetAuthorisedTenantsResponse } from "pages/api/xero/get-authorised-tenants"
import type { GetSavedAuthResponse } from "pages/api/xero/get-saved-auth"

const AuthorisedTenants: React.FC = () => {
  const savedAuth = useSWR<GetSavedAuthResponse>(
    "/api/xero/get-saved-auth",
    (url: string) => fetch(url).then(res => res.json())
  )

  const authorisedTenants = useSWR<GetAuthorisedTenantsResponse>(
    "/api/xero/get-authorised-tenants",
    (url: string) => fetch(url).then(res => res.json())
  )

  if (savedAuth.isLoading || authorisedTenants.isLoading)
    return <LoadingSkeleton className="h-[200px]" />

  if (!savedAuth.data?.token || authorisedTenants.data?.tenants?.length === 0)
    return null

  return (
    <ValidatingWrapper
      isValidating={savedAuth.isValidating || authorisedTenants.isValidating}
    >
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Tenant ID</th>
            <th>Auth event ID</th>
            <th>Created date UTC</th>
            <th>Updated date UTC</th>
            <th>ID</th>
            <th>Tenant name</th>
            <th>Tenant type</th>
          </tr>
        </thead>
        <tbody>
          {authorisedTenants.data?.tenants &&
            authorisedTenants.data.tenants.map(tenant => (
              <TenantRow
                key={tenant.id}
                tenant={tenant}
                currentTenant={savedAuth.data?.token?.tenant_id || null}
                mutate={() => {
                  authorisedTenants.mutate()
                  savedAuth.mutate()
                }}
              />
            ))}
        </tbody>
      </table>
    </ValidatingWrapper>
  )
}

export default AuthorisedTenants
