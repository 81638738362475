import cn from "classnames"

export const Heading: React.FC<
  React.HTMLAttributes<HTMLHeadingElement> & {
    children?: React.ReactNode
    small?: boolean
    as?: React.ElementType
  }
> = ({ children, small, ...rest }) => {
  const headingClass = cn({
    "text-lg leading-8": small,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: headingClass }
  const Tag = rest.as ? rest.as : "h3"
  if (rest.as) {
    delete rest.as
  }
  if (!rest.className) {
    delete rest.className
  }
  return <Tag {...rest}>{children}</Tag>
}

interface ILead extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
}
export const Lead: React.FC<ILead> = ({ children, ...rest }) => {
  const leadClass = cn("text-xl font-bold pb-3", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: leadClass }
  return <p {...rest}>{children}</p>
}

interface IDisplay extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  variant?: "dark" | "light"
}
export const Display: React.FC<IDisplay> = ({ children, variant, ...rest }) => {
  const displayClass = cn("font-light uppercase tracking-widest text-sm", {
    "text-white": variant === "light",
    "text-gray-400": variant === "dark",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: displayClass }
  return <h2 {...rest}>{children}</h2>
}
