import cn from "classnames"
import Link from "next/link"

import FaIcon from "components/FontAwesomeIcon"
import styles from "styles/button.module.scss"

import type { IconName } from "@fortawesome/fontawesome-svg-core"
import type { FaLibraries } from "components/FontAwesomeIcon"

export type ButtonVariants =
  | "default"
  | "primary"
  | "secondary"
  | "tertiary"
  | "white"
  | "black"
  | "highlight"
  | "outlinePrimary"
  | "outlineSecondary"
  | "outlineTertiary"
  | "outlineWhite"
  | "outlineBlack"
  | "outlineHighlight"
  | "linkStyle"
  | "error"
  | "success"
  | "warning"
  | "outlineError"
  | "outlineSuccess"
  | "outlineWarning"

export type ButtonSizes = "sm" | "md" | "lg"

export type ButtonKinds = "href" | "visual" | "submit"

export interface IButton extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode
  content?: string
  href?: string
  kind?: ButtonKinds
  variant?: ButtonVariants
  icon?: IconName
  iconLibrary?: FaLibraries
  iconPosition?: "left" | "right"
  iconSpin?: boolean
  size?: ButtonSizes
  disabled?: boolean
}

export const Button: React.FC<IButton> = ({
  children,
  kind = "href",
  variant = "outlineHighlight",
  icon,
  iconLibrary = "light",
  iconPosition = "left",
  iconSpin = false,
  href = null,
  size = "md",
  ...rest
}) => {
  const btnClass = cn(styles.btn, {
    [styles[size]]: size,
    [styles[variant]]: variant,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: btnClass }

  const internal = href && /^\/(?!\/)/.test(href)
  if (internal) {
    return (
      <Link href={href} {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </Link>
    )
  }

  if (kind === "visual") {
    return (
      <button type="button" {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </button>
    )
  }

  if (kind === "submit") {
    return (
      <button type="submit" {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </button>
    )
  }

  return (
    <a href={href || ""} rel="noopener noreferrer" target="_blank" {...rest}>
      {iconPosition === "left" && (
        <FaIcon
          icon={icon ?? "external-link"}
          library={iconLibrary}
          spin={iconSpin}
          className="mr-2"
        />
      )}
      {children}
      {iconPosition === "right" && (
        <FaIcon
          icon={icon ?? "external-link"}
          library={iconLibrary}
          spin={iconSpin}
          className="ml-2"
        />
      )}
    </a>
  )
}

export const ActionButton: React.FC<
  JSX.IntrinsicElements["button"] & {
    isLoading: boolean
    loadingText?: string
    kind?: ButtonKinds
    size?: ButtonSizes
    variant?: ButtonVariants
    icon?: IconName
    iconLibrary?: "light" | "brands"
    iconPosition?: "left" | "right"
    iconSpin?: boolean
  }
> = ({
  children,
  isLoading,
  loadingText,
  kind = "visual",
  size = "md",
  variant,
  icon,
  iconLibrary = "light",
  iconPosition = "left",
  iconSpin = false,
  ...rest
}) => {
  return (
    <Button
      kind={kind}
      size={size}
      variant={variant}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? (
        <>
          {iconPosition === "left" && (
            <FaIcon icon="spinner-third" className="mr-2" spin />
          )}
          {loadingText ?? "Submitting"}
          {iconPosition === "right" && (
            <FaIcon icon="spinner-third" className="ml-2" spin />
          )}
        </>
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <FaIcon
              icon={icon}
              library={iconLibrary}
              spin={iconSpin}
              className="mr-2"
            />
          )}
          {children}
          {icon && iconPosition === "right" && (
            <FaIcon
              icon={icon}
              library={iconLibrary}
              spin={iconSpin}
              className="ml-2"
            />
          )}
        </>
      )}
    </Button>
  )
}

export type TButtons = React.HTMLAttributes<HTMLDivElement> & {
  buttons: IButton[]
  evenDefaultVariant?: ButtonVariants
  oddDefaultVariant?: ButtonVariants
}

export const Buttons: React.FC<TButtons> = ({
  buttons,
  evenDefaultVariant = "highlight",
  oddDefaultVariant = "outlineHighlight",
  ...rest
}) => {
  const c = cn("flex flex-wrap gap-1", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: c }
  return (
    <div {...rest}>
      {buttons.map((btn, k) => (
        <Button
          key={btn.id || `btn-${k}`}
          {...btn}
          variant={
            btn.variant === "default" || !btn.variant
              ? k % 2 == 0
                ? evenDefaultVariant
                : oddDefaultVariant
              : btn.variant
          }
        >
          {btn.content}
        </Button>
      ))}
    </div>
  )
}
