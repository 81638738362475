import { useState } from "react"

import { signIn } from "next-auth/react"

import { Button } from "components/Button"

import type { IconName } from "@fortawesome/fontawesome-svg-core"
import type { ClientSafeProvider } from "next-auth/react"

const SignInOAuthButton: React.FC<{
  provider: ClientSafeProvider
}> = ({ provider }) => {
  const [clickedOauthButton, setClickedOauthButton] = useState(false)
  return (
    <Button
      icon={clickedOauthButton ? "spinner-third" : (provider.id as IconName)}
      iconLibrary={clickedOauthButton ? "light" : "brands"}
      iconSpin={clickedOauthButton}
      key={provider.id}
      kind="submit"
      variant="outlineWhite"
      onClick={() => {
        setClickedOauthButton(true)
        signIn(provider.id, {
          redirect: false
        })
      }}
    >
      Sign in with {provider.name}
    </Button>
  )
}

export default SignInOAuthButton
