import { createCipheriv, createDecipheriv, randomBytes, scrypt } from "crypto"
import { promisify } from "util"

/**
 * Strip html (used when using page content as meta data)
 *
 * @param {string} str  Pass in string with html
 * @returns             String with no html
 */
export const stripHtml = (str: string): string =>
  str.replace(/(<([^>]+)>)/gi, "")

/**
 * Capitalize
 *
 * @param {*} string
 * @returns
 */
export const capitalize = (string: string): string => {
  if (typeof string !== "string") return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Convert bytes to size
 *
 * @param {int} bytes
 * @see https://gist.github.com/lanqy/5193417
 */
export const bytesToSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "n/a"
  const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

/**
 * Slugify
 *
 * @param {string} string
 * @see https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
 */
export const slugify = (string: string): string => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "") // Replace & with nothing
    .replace(/and/g, "") // Replace and with nothing
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

/**
 * Camelcase to capitalised sentence
 */
export const camelToCapSentence = (text: string): string => {
  const result = text.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}

/**
 * Check if a string is a valid URL
 */
export const isValidUrl = (url: string): boolean => {
  try {
    url && new URL(url)
  } catch {
    console.error(`Invalid URL - ${url}`)
    return false
  }
  return true
}

/**
 * Test to see if valid JSON string
 */
export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Check if status code is success or fail
 */
export const isStatusCodeSuccess = (code: number): boolean => {
  return code >= 200 && code < 300
}

/**
 * Encrypt string
 * @param str string to encrypt
 * @returns
 */
export async function encrypt(str: string): Promise<string> {
  const iv = randomBytes(16)
  const key = (await promisify(scrypt)(
    process.env.ENCRYPT_KEY,
    "salt",
    32
  )) as Buffer
  const cipher = createCipheriv("aes-256-ctr", key, iv)
  const encrypted = Buffer.concat([cipher.update(str), cipher.final()])
  return `${iv.toString("hex")}:${encrypted.toString("hex")}`
}

/**
 * Decrypt string
 * @param str string to decrypt
 * @returns
 */
export async function decrypt(encryptedStr: string): Promise<string> {
  const [iv, encryptedText] = encryptedStr.split(":")
  const key = (await promisify(scrypt)(
    process.env.ENCRYPT_KEY,
    "salt",
    32
  )) as Buffer
  const decipher = createDecipheriv("aes-256-ctr", key, Buffer.from(iv, "hex"))
  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(encryptedText, "hex")),
    decipher.final()
  ])
  return decrypted.toString()
}
