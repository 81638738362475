import SignInOAuthButton from "./SignInOAuthButton"

import type { NextAuthProviders } from "pages/index"

const SignInOAuth: React.FC<{
  providers: NextAuthProviders
}> = ({ providers }) => {
  return (
    <div className="flex flex-col gap-2">
      {Object.values(providers).map(provider =>
        provider.id !== "google" ? (
          <SignInOAuthButton key={provider.id} provider={provider} />
        ) : null
      )}
    </div>
  )
}

export default SignInOAuth
