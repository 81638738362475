import { useState } from "react"

import { ActionButton } from "components/Button"

import type { GoogleBusinessApiResponse } from "pages/api/google-business"

const GetLocationData: React.FC = () => {
  const [accountsAndLocations, setAccountsAndLocations] =
    useState<GoogleBusinessApiResponse | null>(null)
  const [isGettingAccountsAndLocations, setIsGettingAccountsAndLocations] =
    useState<boolean>(false)

  return (
    <>
      <p>
        Click to get a list of connected locations. Grab the{" "}
        <code>locations[x]name</code> to make a request below to get performance
        data.
      </p>
      <ActionButton
        isLoading={isGettingAccountsAndLocations}
        loadingText="Getting..."
        onClick={async () => {
          setIsGettingAccountsAndLocations(true)
          const res: GoogleBusinessApiResponse = await fetch(
            "/api/google-business"
          ).then(r => r.json())
          setAccountsAndLocations(res)
          setIsGettingAccountsAndLocations(false)
        }}
      >
        View connected Google Business accounts
      </ActionButton>
      <pre className="text-sm">
        {JSON.stringify(accountsAndLocations, null, 2)}
      </pre>
    </>
  )
}

export default GetLocationData
