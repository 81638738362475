import { useState } from "react"

import Head from "next/head"
import { getProviders, signOut, useSession } from "next-auth/react"

import SignInOAuth from "components/admin/auth/SignInOAuth"
import GetLocationData from "components/admin/google-business/GetLocationData"
import GetPerformanceData from "components/admin/google-business/GetPerformanceData"
import LinkedAccount from "components/admin/google-business/LinkedAccount"
import AuthorisedTenants from "components/admin/xero/AuthorisedTenants"
import LinkXero from "components/admin/xero/LinkXero"
import { ActionButton } from "components/Button"
import Layout, { Container, Section } from "components/Layout"
import { Heading } from "components/Text"

import type { GetServerSideProps, NextPage } from "next"
import type { BuiltInProviderType } from "next-auth/providers"
import type { ClientSafeProvider, LiteralUnion } from "next-auth/react"

export type NextAuthProviders = Record<
  LiteralUnion<BuiltInProviderType, string>,
  ClientSafeProvider
>

const Admin: NextPage<{
  providers: NextAuthProviders
}> = ({ providers }) => {
  const { status } = useSession()
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false)

  return (
    <>
      <Head>
        {/* No index */}
        <meta name="robots" content="noindex" />
      </Head>
      <Layout>
        <Section>
          <Container>
            {status === "loading" && <p>Loading...</p>}
            {status === "unauthenticated" && (
              <SignInOAuth providers={providers} />
            )}
            {status === "authenticated" && (
              <div className="flex flex-col gap-4">
                <div className="border-b border-b-gray-700 mb-5 pb-5">
                  <ActionButton
                    isLoading={isSigningOut}
                    loadingText="Signing out..."
                    variant="outlineWhite"
                    onClick={() => {
                      setIsSigningOut(true)
                      signOut()
                    }}
                  >
                    Sign out
                  </ActionButton>
                </div>
                <div className="border-b border-b-gray-700 mb-5 pb-5">
                  <Heading>Xero</Heading>
                  <LinkXero />
                  <AuthorisedTenants />
                </div>
                <div>
                  <Heading>Google Business</Heading>
                  <LinkedAccount googleProvider={providers.google} />
                  <GetLocationData />
                  <GetPerformanceData />
                </div>
              </div>
            )}
          </Container>
        </Section>
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  const providers = await getProviders()

  return {
    props: { providers }
  }
}

export default Admin
