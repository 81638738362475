import cn from "classnames"

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <div id="layout">
        <main>{children}</main>
      </div>
    </>
  )
}
export default Layout

export const Section: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode
  }
> = ({ children, ...rest }) => {
  const sectionClass = cn("relative py-6", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: sectionClass }
  return <section {...rest}>{children}</section>
}

export const Container: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    variant?: "container" | "full" | "left" | "right"
    children?: React.ReactNode
  }
> = ({ variant = "container", children, ...rest }) => {
  const containerClass = cn({
    "container relative": variant === "container",
    "mx-auto px-5": variant === "full",
    "pl-10": variant === "left",
    "pr-10": variant === "right",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: containerClass }
  return <div {...rest}>{children}</div>
}

export const Row: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode
    gap?: boolean
  }
> = ({ children, gap = true, ...rest }) => {
  const rowClass = cn("flex flex-wrap", {
    "gap-6": gap,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: rowClass }

  return <div {...rest}>{children}</div>
}
