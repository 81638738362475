import cn from "classnames"

const ValidatingWrapper: React.FC<
  JSX.IntrinsicElements["div"] & { isValidating: boolean }
> = ({ isValidating, children, ...rest }) => {
  const cl = cn({
    "pointer-events-none": isValidating,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: cl }

  return (
    <div className={isValidating ? "opacity-50 cursor-not-allowed" : ""}>
      <div {...rest}>{children}</div>
    </div>
  )
}

export default ValidatingWrapper
