import { useState } from "react"

import useSWR from "swr"

import { ActionButton } from "components/Button"
import LoadingSkeleton from "components/LoadingSkeleton"
import ValidatingWrapper from "components/ValidatingWrapper"

import type { GetAuthorisedTenantsResponse } from "pages/api/xero/get-authorised-tenants"
import type { GetSavedAuthResponse } from "pages/api/xero/get-saved-auth"

const LinkXero: React.FC = () => {
  const savedAuth = useSWR<GetSavedAuthResponse>(
    "/api/xero/get-saved-auth",
    (url: string) => fetch(url).then(res => res.json())
  )

  const authorisedTenants = useSWR<GetAuthorisedTenantsResponse>(
    "/api/xero/get-authorised-tenants",
    (url: string) => fetch(url).then(res => res.json())
  )

  const [buttonLoading, setButtonLoading] = useState(false)

  if (savedAuth.isLoading || authorisedTenants.isLoading)
    return <LoadingSkeleton className="h-[200px]" />

  if (
    savedAuth.data?.token &&
    (authorisedTenants.data?.tenants?.length || 0) > 0
  )
    return null

  return (
    <ValidatingWrapper
      isValidating={savedAuth.isValidating || authorisedTenants.isValidating}
    >
      <ActionButton
        isLoading={buttonLoading}
        loadingText="Getting consent..."
        onClick={async () => {
          setButtonLoading(true)

          const url = new URL(
            "https://login.xero.com/identity/connect/authorize"
          )
          url.searchParams.append("response_type", "code")
          url.searchParams.append(
            "client_id",
            process.env.NEXT_PUBLIC_XERO_CLIENT_ID
          )
          url.searchParams.append(
            "redirect_uri",
            `${process.env.NEXT_PUBLIC_SITE_URL}/api/xero/callback`
          )
          url.searchParams.append(
            "scope",
            "openid profile email accounting.transactions accounting.contacts accounting.settings offline_access"
          )

          const authWindow = window.open(url, "_blank")

          authWindow?.focus()
          const timer = setInterval(async () => {
            if (authWindow?.closed) {
              setButtonLoading(false)
              clearInterval(timer)
            }
          }, 500)
        }}
      >
        Click to link Xero
      </ActionButton>
      <pre>{JSON.stringify(savedAuth.data, null, 2)}</pre>
    </ValidatingWrapper>
  )
}

export default LinkXero
