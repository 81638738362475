import { useState } from "react"

import { ActionButton } from "components/Button"

import type {
  LinkTenantRequest,
  LinkTenantResponse
} from "pages/api/xero/link-tenant"
import type {
  UnlinkTenantRequest,
  UnlinkTenantResponse
} from "pages/api/xero/unlink-tenant"
import type { Tenant } from "types/xero"

const TenantRow: React.FC<{
  tenant: Tenant
  currentTenant: string | null
  mutate: () => void
}> = ({ tenant, currentTenant, mutate }) => {
  const [linking, setLinking] = useState(false)
  const [unlinking, setUnlinking] = useState(false)

  return (
    <tr key={tenant.tenantId}>
      <td>
        {tenant.tenantId === currentTenant ? (
          <span className="text-success uppercase font-bold">Linked</span>
        ) : (
          <ActionButton
            size="sm"
            variant="primary" // gradient button has custom padding
            isLoading={linking}
            loadingText="Linking..."
            onClick={async () => {
              setLinking(true)

              const res: LinkTenantResponse = await fetch(
                "/api/xero/link-tenant",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    tenant_id: tenant.tenantId
                  } as LinkTenantRequest["body"])
                }
              ).then(res => res.json())
              if (res.statusCode === 200) {
                mutate()
              }
              setLinking(false)
            }}
          >
            Use this tenant
          </ActionButton>
        )}
      </td>
      <td>
        <ActionButton
          size="sm"
          variant="primary" // gradient button has custom padding
          isLoading={unlinking}
          loadingText="Unlinking..."
          onClick={async () => {
            setUnlinking(true)

            const res: UnlinkTenantResponse = await fetch(
              "/api/xero/unlink-tenant",
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  connection_id: tenant.id
                } as UnlinkTenantRequest["body"])
              }
            ).then(res => res.json())
            if (res.statusCode === 200) {
              mutate()
            }
            setUnlinking(false)
          }}
        >
          Unlink
        </ActionButton>
      </td>
      <td>{tenant.tenantId}</td>
      <td>{tenant.authEventId}</td>
      <td>{tenant.createdDateUtc}</td>
      <td>{tenant.updatedDateUtc}</td>
      <td>{tenant.id}</td>
      <td>{tenant.tenantName}</td>
      <td>{tenant.tenantType}</td>
    </tr>
  )
}

export default TenantRow
