import { useState } from "react"

import useSWR from "swr"

import FaIcon from "components/FontAwesomeIcon"
import LoadingSkeleton from "components/LoadingSkeleton"
import ValidatingWrapper from "components/ValidatingWrapper"

import SignInOAuthButton from "../auth/SignInOAuthButton"

import type { ClientSafeProvider } from "next-auth/react"
import type { GetLinkedGoogleAccountResponse } from "pages/api/google-account/get-linked"
import type {
  UnlinkGoogleAccountApiRequest,
  UnlinkGoogleAccountApiResponse
} from "pages/api/google-account/unlink"

const LinkedAccount: React.FC<{
  googleProvider: ClientSafeProvider
}> = ({ googleProvider }) => {
  const [unlinking, setUnlinking] = useState(false)
  const { data, error, isValidating, isLoading, mutate } =
    useSWR<GetLinkedGoogleAccountResponse>("/api/google-account/get-linked")

  const unlinkAccount = async () => {
    setUnlinking(true)
    const res: UnlinkGoogleAccountApiResponse = await fetch(
      "/api/google-account/unlink",
      {
        method: "DELETE",
        body: JSON.stringify({
          id: data?.accountId
        } as UnlinkGoogleAccountApiRequest["body"])
      }
    ).then(res => res.json())

    if (res.statusCode === 200) {
      mutate()
    }
    setUnlinking(false)
  }

  if (error) return <p>Failed to load</p>

  if (isLoading) return <LoadingSkeleton className="h-[200px]" />

  return (
    <ValidatingWrapper isValidating={isValidating || unlinking}>
      {data?.userEmail ? (
        <p>
          {data.userEmail} is linked.{" "}
          <span
            onClick={unlinkAccount}
            className="font-bold href-style with-underline"
          >
            Click to unlink {unlinking && <FaIcon icon="spinner-third" spin />}
          </span>
          .
        </p>
      ) : (
        <>
          <p>
            One account can be linked. (Maybe add scope to link more in future
            if needed).
          </p>
          <SignInOAuthButton provider={googleProvider} />
        </>
      )}
    </ValidatingWrapper>
  )
}

export default LinkedAccount
