import { useState } from "react"

import { useForm } from "react-hook-form"

import { ActionButton } from "components/Button"
import { Input } from "components/forms/FormElements"

import type {
  GoogleBusinessApiRequest,
  GoogleBusinessApiResponse
} from "pages/api/google-business"

const GetPerformanceData: React.FC = () => {
  const [performance, setPerformance] =
    useState<GoogleBusinessApiResponse | null>(null)
  const [isGettingPerformance, setIsGettingPerformance] =
    useState<boolean>(false)

  const rhf = useForm()

  return (
    <>
      <Input
        type="text"
        placeholder="Location name"
        {...rhf.register("location")}
      />
      <Input
        type="date"
        placeholder="Start date"
        {...rhf.register("startDate")}
      />
      <Input type="date" placeholder="End date" {...rhf.register("endDate")} />
      <ActionButton
        isLoading={isGettingPerformance}
        loadingText="Getting..."
        onClick={async () => {
          setIsGettingPerformance(true)
          const res: GoogleBusinessApiResponse = await fetch(
            "/api/google-business",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                location: rhf.getValues("location"),
                startDate: rhf.getValues("startDate"),
                endDate: rhf.getValues("endDate")
              } as GoogleBusinessApiRequest["body"])
            }
          ).then(r => r.json())
          setPerformance(res)
          setIsGettingPerformance(false)
        }}
      >
        Get performance data
      </ActionButton>
      <pre className="text-sm">{JSON.stringify(performance, null, 2)}</pre>
    </>
  )
}

export default GetPerformanceData
