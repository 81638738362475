import cn from "classnames"

import FaIcon from "./FontAwesomeIcon"

import type { SizeProp } from "@fortawesome/fontawesome-svg-core"

export default function LoadingSkeleton({
  spinnerSize = "3x",
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  spinnerSize?: SizeProp
}): JSX.Element {
  const wrapperClass = cn(
    "animate-pulse bg-gray-800 flex items-center justify-center",
    {
      [rest?.className as string]: !!rest?.className
    }
  )
  rest = { ...rest, className: wrapperClass }
  return (
    <div {...rest}>
      <FaIcon
        icon="spinner-third"
        spin
        size={spinnerSize}
        className="text-primary"
      />
    </div>
  )
}
